import React, { useState } from 'react'
import './emailpopup.css'
import { useDispatch, useSelector } from 'react-redux'
import {
  saveEmailId,
  postInformationToDiscord
} from '../../action/HomePage.action'

const Popup = ({ buttonName, hrefUrl, onClose }) => {
  const dispatch = useDispatch()

  const [email, setEmail] = useState('')

  function handleEmail (event) {
    setEmail(event.target.value)
  }

  function encodeEmailToBase64 (email) {
    const encodedEmail = btoa(email)
    return encodedEmail
  }

  function handleButton (e) {
    dispatch(saveEmailId(email))
    dispatch(postInformationToDiscord(email))
    onClose()

    console.log('EmailPopup.js handleButton', buttonName)

    const encodedEmail = encodeEmailToBase64(email)

    const buttonText = e.target.innerText

    e.preventDefault()
    const baseUrl = e.currentTarget.getAttribute('href')
    const fullUrl = `${baseUrl}?email=${encodedEmail}`
    window.location.href = fullUrl
  }

  return (
    <div className='email-popup'>
      <button className='email-close-button' onClick={onClose}>
        &times;
      </button>
      <div className='email-popup-content-div'>
        <div className='email'>
          <label className='email-first-name-label'></label>
          <input
            className='email-phone-input'
            placeholder='Email'
            typemail-phone='email'
            onChange={handleEmail}
          />
        </div>
        <a
          type='button'
          onClick={handleButton}
          className='redirect-btn'
          href={hrefUrl}
        >
          {buttonName}
        </a>
      </div>
    </div>
  )
}

export default Popup
