import React from 'react';
import MobileJobExperience from './MobileJobExperience';
import TabletJobExperience from './TabletJobExperience';
import DesktopJobExperience from './DesktopJobExperience';
import { useMediaQuery } from 'react-responsive';

const Feature = () => {

  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const isTablet = useMediaQuery({ query: '(min-width: 768px) and (max-width: 1024px)' });
  const isDesktop = useMediaQuery({ query: '(min-width: 1025px)' });

  return (
    <div>
      {
        isMobile ? <MobileJobExperience /> : isTablet ? <TabletJobExperience /> : <DesktopJobExperience />
      }
    </div>
  )
}

export default Feature
