import './hero.css'
import { useMediaQuery } from 'react-responsive';

const Hero = () => {
  const isMobile = useMediaQuery({ query: '(min-width: 400px) and (max-width: 415px)' })

  return (
    <section
      id='hero'
      className='hero d-flex flex-column justify-content-center'
    >
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-xl-8'>
              <h1 className='identify-text'>
                <sup style={{ fontFamily: 'Qwigley' }} className='identify'>
                  Identify
                </sup>
                <span style={{fontFamily:'Poppins, sans-serif'}}>
                  {`your dream job by${isMobile ? '\n': ' '}trying multiple virtual${isMobile ? '\n': ' '}jobs`}
                </span>
              </h1>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero
