import React from 'react';
import MobileFeature from './MobileFeature';
import TabletFeature from './TabletFeature';
import DesktopFeature from './DesktopFeature';
import { useMediaQuery } from 'react-responsive';

const Feature = () => {

  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const isTablet = useMediaQuery({ query: '(min-width: 768px) and (max-width: 1024px)' });
  const isDesktop = useMediaQuery({ query: '(min-width: 1025px)' });

  return (
    <div>
      {
        isMobile ? <MobileFeature /> : isTablet ? <TabletFeature /> : <DesktopFeature />
      }
    </div>
  )
}

export default Feature
