import React from 'react'
import ReactPlayer from 'react-player/lazy'

// Lazy load the YouTube player

const YoutubePlayer = () => {
    return (
        <ReactPlayer url='https://www.youtube.com/watch?v=ypHW-w1rNBA&feature=youtu.be' />
    )
}

export default YoutubePlayer;