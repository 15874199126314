const initialState = {
  job_list: [],
  virtual_jobs: [],
  user_details: [],
  email: ""
}
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_VIRTUAL_JOBS':
      return {
        ...state,
        virtual_jobs: action.payload
      }

    case 'JOB_LIST':
      return {
        ...state,
        job_list: action.payload
      }

    case 'SAVE_EMAIL_ID':
      return {
        ...state,
        email: action.payload
      }
    default:
      return state
  }
}
export default reducer
