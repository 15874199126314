import React from 'react'
import './mentorpopup.css'

const Popup = ({ onClose }) => {
  return (
    <div className='popup'>
      <button className='close-button' onClick={onClose}>
        &times;
      </button>
      <div className='popup-main-div'>
        <img
          className='popup-terafac-logo'
          src={require('../../assets/mentor-logo.png')}
          alt=''
        />
        <div className='popup-content-div'>
          <h2 className='popup-heading'>Not sure what you are looking for?</h2>
          <p className='popup-paragraph'>Talk to Our Mentor Now</p>
          <a
            type='button'
            className='start-chating-btn'
            href='https://mentor.terafac.live'
          >
            Start Chatting
          </a>
        </div>
      </div>
    </div>
  )
}

export default Popup
