import React from 'react'
import './tabletfeature.css'
import { useMediaQuery } from 'react-responsive';

const TabletFeature = () => {

  const mobile = useMediaQuery({ query: '(max-width: 640px)' })

  return (
    <div className='tf-feature-column d-flex flex-row'>
      <div className='tf-feature-first-column col-4'>
        <div className='tf-first-pic-div'>
          <img
            className='tf-feature-first-pic'
            src={require('../../assets/feature-pic3.jpg')}
            alt=''
          />
        </div>
        <div className='tf-second-pic-div'>
          <img
            className='tf-feature-second-pic'
            src={require('../../assets/feature-pic1.jpg')}
            alt=''
          />
          <h1 className='tf-terafac-live'>
            Terafac <span style={{fontWeight: 400}}>Live</span>
          </h1>
          <img
            className='tf-feature-third-pic'
            src={require('../../assets/feature-pic2.jpg')}
            alt=''
          />
        </div>
      </div>
      <div className='tf-feature-second-column col-8'>
        <div className='col-9 tf-features-div'>
          <div className='tf-icon-container'>
            <img
              style={mobile ? { width: 25, height: 25 } : { width: 46, height: 46 }}
              className='tf-feature-icon'
              src={require('../../assets/feature-icon3.png')}
              alt=''
            />
          </div>
          <h2>Test drive jobs before you make your next career move.</h2>
        </div>
        <div className='col-9 tf-features-div offset-2'>
          <div className='tf-icon-container'>
            <img
              style={mobile ? { width: 22, height: 22 } : { width: 32, height: 32 }}
              className='tf-feature-icon'
              src={require('../../assets/feature-icon4.png')}
              alt='image'
            />
          </div>
          <h2>Get skill assessment report and experience certificate.</h2>
        </div>
        <div className='col-9 tf-features-div'>
          <div className='tf-icon-container'>
            <img
              style={mobile ? { width: 22, height: 22 } : { width: 32, height: 32 }}
              className='tf-feature-icon'
              src={require('../../assets/feature-icon2.png')}
              alt=''
            />
          </div>
          <h2>Get Automated professional CV and match with top employers.</h2>
        </div>
        <div className='col-9 tf-features-div offset-2'>
          <div className='tf-icon-container'>
            <img
              style={mobile ? { width: 25, height: 22 } : { width: 35, height: 32 }}
              className='tf-feature-icon'
              src={require('../../assets/feature-icon1.png')}
              alt=''
            />
          </div>
          <h2>Unlock your future with smart Job Suggestions.</h2>
        </div>
      </div>
    </div>
  )
}

export default TabletFeature;
