import React from 'react'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import { FaBeer } from 'react-icons/fa'
import './carousel.css'
import carouselPic2 from '../../assets/carousel-pic2.png'
import carouselPic3 from '../../assets/carousel-pic3.jpeg'
import carouselPic4 from '../../assets/carousel-pic4.jpeg'
import carouselPic5 from '../../assets/carousel-pic5.webp'
import carouselPic6 from '../../assets/carousel-pic6.jpeg'

const MyCarousel = () => {
  const ourprograms = [
    {
      id: 0,
      image: "https://s3.us-west-1.amazonaws.com/assets.terafac.live/images/frontend_developer.png",
      title: 'Frontend Developer - React Native',
      companies_list: [
        'https://s3.us-west-1.amazonaws.com/assets.terafac.live/images/google.png',
        'https://repetition-images.s3.ap-south-1.amazonaws.com/infosys.png',
        'https://s3.us-west-1.amazonaws.com/assets.terafac.live/images/meesho-preview.png'
      ],
      company: 'The Startup Live',
      address: 'Intermediate',
      enter: 'Enter',
      package: '',
      openings: '',
      qualification: ' ',
      experience: '  ',
      description:
        'Design, implement React UI components; coordinate with teams; ensure performance; integrate native APIs; manage Redux architecture.',
      job_type: 'On-site',
      banner_image:
        'https://www.websoptimization.com/external/upload/framework-banner/desktop/hire-react-native-mobile-app-programmer-india.jpg',
      btn: 'Read More'
    },
    {
      id: 1,
      image: carouselPic2,
      title: 'Project Manager - IT ( Coming Soon )',
      companies_list: [
        'https://s3.us-west-1.amazonaws.com/assets.terafac.live/images/google.png',
        'https://repetition-images.s3.ap-south-1.amazonaws.com/infosys.png',
        'https://s3.us-west-1.amazonaws.com/assets.terafac.live/images/meesho-preview.png'
      ],
      company: 'The Startup Live',
      address: 'Intermediate',
      enter: 'Enter',
      package: ' ',
      openings: '',
      qualification: ' ',
      experience: '  ',
      description:
        'Seeking IT Web Developer for server-side logic, database maintenance, front-end integration, and responsiveness.',
      job_type: 'Full time',
      banner_image:
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTFnall4lRPrUiMchfInSQf16_dCRTeGcgyLnseouBUcDEDXLqK1j07iqJESxC2fxfNlck&usqp=CAU',
      btn: 'Read More'
    },
    {
      id: 2,
      image: carouselPic3,
      title: 'Digital Marketing ( Coming Soon )',
      companies_list: [
        'https://s3.us-west-1.amazonaws.com/assets.terafac.live/images/google.png',
        'https://repetition-images.s3.ap-south-1.amazonaws.com/infosys.png',
        'https://s3.us-west-1.amazonaws.com/assets.terafac.live/images/meesho-preview.png'
      ],
      company: 'The Startup Live',
      address: 'Intermediate',
      enter: 'Coming Soon',
      package: '',
      openings: '',
      qualification: ' ',
      experience: '  ',
      description:
        'Seeking React developer for UI design, native integrations, testing, efficiency, and cross-functional team coordination.',
      job_type: 'On-site',
      btn: 'Read More'
    },
    {
      id: 3,
      image: carouselPic4,
      title: 'IOT Engineer ( Coming Soon )',
      companies_list: [
        'https://s3.us-west-1.amazonaws.com/assets.terafac.live/images/google.png',
        'https://repetition-images.s3.ap-south-1.amazonaws.com/infosys.png',
        'https://s3.us-west-1.amazonaws.com/assets.terafac.live/images/meesho-preview.png'
      ],
      company: 'The Startup Live',
      address: 'Intermediate',
      enter: 'Coming Soon',
      package: ' ',
      openings: '',
      qualification: ' ',
      experience: '  ',
      description:
        'IoT Engineer: Manage data interchange, server logic, database, performance, security, and front-end integration.',
      job_type: 'Full time',
      btn: 'Read More'
    },
    {
      id: 4,
      image: carouselPic5,
      title: 'Python Developer  ( Coming Soon )',
      companies_list: [
        'https://s3.us-west-1.amazonaws.com/assets.terafac.live/images/google.png',
        'https://repetition-images.s3.ap-south-1.amazonaws.com/infosys.png',
        'https://s3.us-west-1.amazonaws.com/assets.terafac.live/images/meesho-preview.png'
      ],
      company: 'The Startup Live',
      address: 'Advanced',
      enter: 'Coming Soon',
      package: '',
      openings: '',
      qualification: ' ',
      experience: '  ',
      description:
        'Python Developer: Manage data, server logic, database, optimization, security, and integrate front-end elements.',
      job_type: 'Remote',
      btn: 'Read More'
    },
    {
      id: 5,
      image: carouselPic6,
      title: 'Business Development Manager \n ( Coming Soon )',
      companies_list: [
        'https://s3.us-west-1.amazonaws.com/assets.terafac.live/images/google.png',
        'https://repetition-images.s3.ap-south-1.amazonaws.com/infosys.png',
        'https://s3.us-west-1.amazonaws.com/assets.terafac.live/images/meesho-preview.png'
      ],
      company: 'The Startup Live',
      address: 'Advanced',
      enter: 'Coming Soon',
      package: '',
      openings: '',
      qualification: ' ',
      experience: '  ',
      description:
        "Data Scientist: Transform data, deploy machine learning, ensure insights' accuracy, collaborate, understand software lifecycle.",
      job_type: 'Remote',
      btn: 'Read More'
    }
  ]

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1
    }
  }

  return (
    <div className='my-own-custom-container'>
      <div className='' data-aos='fade-up'>
        <h1 className='main-carousel-heading fs-xl-heading'>
          Featured virtual jobs
        </h1>
      </div>
      <div className='container-fluid'>
        <Carousel
          swipeable={true}
          draggable={true}
          responsive={responsive}
          ssr={true}
          infinite={true}
          autoPlay={false}
          autoPlaySpeed={5000}
          keyBoardControl={true}
          customTransition='all .5'
          transitionDuration={500}
          containerClass='carousel-container'
          //   removeArrowOnDeviceType={["tablet", "mobile"]}
          itemClass='carousel-item-padding-10-px'
          showDots={false}
          arrows={true}
        >
          {ourprograms?.map((item, index) => (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
              className='card-bg-remove'
              key={index}
            >
              <Card
                style={{
                  width: '20rem',
                  height: 420,
                  background: '#EDF8F8',
                  margin: '10px'
                }}
                className='rounded-img '
              >
                <div className='card-img-wrapper'>
                  <Card.Img
                    variant='top'
                    src={item.image}
                    style={{ height: 230, width: '100%', objectFit: "cover" }}
                    className='rounded-img'
                  />
                </div>
                <Card.Body>
                  <span
                    style={{ fontSize: 16 }}
                    className='font-family-description'
                  >
                    <a
                      href='https://jex.terafac.live'
                      style={{ textDecoration: 'none' }}
                    >
                      <h5
                        className='fs-xl-para'
                        style={{
                          textAlign: 'center',
                          color: '#3FA5AF',
                          fontSize: 18
                        }}
                      >
                        {item.title}
                      </h5>
                    </a>
                    <p className='fs-sm-para' style={{ textAlign: 'center' }}>
                      {' '}
                      {item.description}
                    </p>
                  </span>
                </Card.Body>
              </Card>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  )
}

export default MyCarousel
