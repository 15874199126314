import React, { useEffect, useState } from 'react'
import './whoweare.css'
import EmailPopup from '../EmailPopup/EmailPopup'
import { useDispatch, useSelector } from 'react-redux'
import { postInformationToDiscord } from '../../action/HomePage.action'

const WhoWeAre = () => {
  const [showEmailPopup, setShowEmailPopup] = useState(false)
  const [popupContent, setPopupContent] = useState({ href: '', buttonText: '' })

  const handleEmailPopupClose = () => {
    setShowEmailPopup(false)
  }

  const savedEmail = useSelector(state => state.home.email)

  const dispatch = useDispatch()

  function encodeEmailToBase64 (email) {
    console.log('Experience encodeEmailToBase64 email', email)
    const encodedEmail = btoa(email)
    return encodedEmail
  }

  const handleEmailPopupOpen = event => {
    // alert("handleEmailPopupOpen function called")
    console.log('Navbar handleEmailPopupOpen savedEmail', savedEmail)

    const encodedEmail = encodeEmailToBase64(savedEmail)
    const buttonText = event.target.innerText
    const buttonName = event.target.getAttribute('name')
    const href = event.target.getAttribute('href')

    if (buttonName == 'Enter JEX') {
      if (savedEmail == '') {
        event.preventDefault()
        const baseUrl = event.currentTarget.getAttribute('href')
        const fullUrl = `${baseUrl}`
        window.location.href = fullUrl
      } else {
        dispatch(postInformationToDiscord(savedEmail))
        event.preventDefault()
        const baseUrl = event.currentTarget.getAttribute('href')
        const fullUrl = `${baseUrl}?email=${encodedEmail}`
        window.location.href = fullUrl
      }
    } else if (buttonName == 'Enter Discord') {
      if (savedEmail == '') {
        // alert(href)
        event.preventDefault()
        setPopupContent({ href, buttonText })
        setShowEmailPopup(true)
      } else {
        dispatch(postInformationToDiscord(savedEmail))
        event.preventDefault()
        const baseUrl = event.currentTarget.getAttribute('href')
        const fullUrl = `${baseUrl}`
        window.location.href = fullUrl
      }
    } else {
      if (savedEmail == '') {
        // alert(href)
        event.preventDefault()
        setPopupContent({ href, buttonText })
        setShowEmailPopup(true)
      } else {
        dispatch(postInformationToDiscord(savedEmail))
        event.preventDefault()
        const baseUrl = event.currentTarget.getAttribute('href')
        const fullUrl = `${baseUrl}?email=${encodedEmail}`
        window.location.href = fullUrl
      }
    }
  }

  return (
    <div id='whoweare' className='whoweare'>
      {/* <h4 style={{color: "black"}}>savedEmail{JSON.stringify(savedEmail)}</h4> */}
      <div className='whoweare-main d-flex flex-column container'>
        <div className='whoweare-section-title d-flex align-items-center justify-content-center flex-column'>
          <h1 className='experience-job-heading  font-family-description fs-xl-heading mobile-heading'>
            Who we are <span className='question-mark'>?</span>
          </h1>
          <p className='fs-xl-para text-center'>
            <span className='terafac-title description-size'>Terafac</span> Live
            is a virtual career exploration platform where individuals can
            sample and experience various jobs in an immersive virtual
            environment. Users can interact with real and simulated tools,
            managers, peers, and customers to get a taste of different
            professions before making real-world career decisions.{' '}
            <span className='terafac-title description-size'>Terafac.</span>live
            aims to assist users in finding their ideal career path by offering
            realistic job simulations and career guidance.
          </p>
        </div>

        <div className='company-features d-flex align-items-center justify-content-center'>
          <div className='company-feature d-flex align-items-center justify-content-center'>
            <div className='feature-icon-box'>
              <div className='feature-title-box'>
                <div className='img-div'>
                  <img
                    className='whoweare-icon'
                    src={require('../../assets/whoweare-icon1.png')}
                    alt=''
                  />
                </div>
                <h4 className='text-center fs-xl-para'>
                  Immersive Virtual Job Simulations
                </h4>
              </div>
              <p className='text-center fs-sm-para'>
                Terafac offers highly immersive and realistic job simulations
                across a wide range of industries.
              </p>
              <a
                name='Enter JEX'
                className='enter-jex-button text-decoration-none d-flex justify-content-center align-items-center'
                href='https://jex.terafac.live/'
                onClick={handleEmailPopupOpen}
              >
                Enter JEX
              </a>
            </div>
          </div>

          <div className='company-feature d-flex align-items-center justify-content-center'>
            <div className='feature-icon-box'>
              <div className='feature-title-box'>
                <div className='img-div'>
                  <img
                    className='whoweare-icon'
                    src={require('../../assets/whoweare-icon2.png')}
                    alt=''
                  />
                </div>
                <h4 className='col-12 text-center fs-xl-para'>
                  AI-Driven Interaction
                </h4>
              </div>
              <p className='text-center fs-sm-para'>
                The platform enables users to interact with AI-powered managers,
                colleagues, and customers.
              </p>
              <a
                name='Enter Discord'
                href='https://discord.gg/s22CzNab7E'
                onClick={handleEmailPopupOpen}
                className='enter-jex-button text-decoration-none d-flex justify-content-center align-items-center'
              >
                Enter Discord
              </a>
            </div>
          </div>

          <div className='company-feature d-flex align-items-center justify-content-center'>
            <div className='feature-icon-box'>
              <div className='feature-title-box'>
                <div className='img-div'>
                  <img
                    className='whoweare-icon'
                    src={require('../../assets/whoweare-icon3.png')}
                    alt=''
                  />
                </div>
                <h4 className='text-center fs-xl-para'>
                  Personalized Career Guidance
                </h4>
              </div>
              <p className='text-center fs-sm-para'>
                Terafac provides users with tailored career guidance based on
                their performance in the virtual job simulations.
              </p>
              <a
                href='https://mentor.terafac.live/'
                className='enter-jex-button text-decoration-none d-flex justify-content-center align-items-center'
                onClick={handleEmailPopupOpen}
              >
                Talk to our Mentor
              </a>
            </div>
          </div>
        </div>
      </div>
      {showEmailPopup && (
        <EmailPopup
          buttonName={popupContent.buttonText}
          hrefUrl={popupContent.href}
          onClose={handleEmailPopupClose}
        />
      )}
    </div>
  )
}

export default WhoWeAre
