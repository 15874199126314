import React from 'react'
import './mobilefeature.css'
import { useMediaQuery } from 'react-responsive'

const MobileFeature = () => {
  const mobile = useMediaQuery({ query: '(max-width: 640px)' })

  return (
    <div className='feature-column d-flex flex-sm-column flex-md-column flex-lg-row flex-xl-row flex-xxl-row'>
      <div className=' d-f flex-row col-sm-12 col-md-12 col-lg-5 col-xl-5 col-xxl-5'>
        <div className='first-pic-div'>
          {/* <img
            className='feature-first-pic'
            src={require('../../assets/feature-pic3.jpg')}
            alt=''
          /> */}
        </div>
        <h1 className='text'>
          Terafac<span className='live-span'> Live</span>
        </h1>
        <div className='second-pic-div' style={{ position: 'relative' }}>
          <img
            className='feature-first-pic'
            style={{
              position: '',
              top: 0,
              left: '0%',
              transform: 'translateX(-79%)'
            }}
            src={require('../../assets/feature-pic3.jpg')}
            alt=''
          />
          <img
            className='feature-second-pic'
            style={{
              position: 'absolute',
              top: 0,
              left: '47%',
              transform: 'translateX(-40%)'
            }}
            src={require('../../assets/feature-pic1.jpg')}
            alt=''
          />
          <img
            className='feature-third-pic'
            style={{
              position: 'absolute',
              bottom: 0,
              left: '65%',
              transform: 'translateX(-30%)'
            }}
            src={require('../../assets/feature-pic2.jpg')}
            alt=''
          />
        </div>
      </div>
      <div className='feature-second-column col-sm-12 col-md-12 col-lg-7 col-xl-7 col-xxl-7 '>
        <div className='col-10 features-div ms-0 '>
          <div className='icon-container'>
            <img
              style={
                mobile ? { width: 25, height: 25 } : { width: 56, height: 56 }
              }
              className='feature-icon'
              src={require('../../assets/feature-icon3.png')}
              alt=''
            />
          </div>
          <h2>Test drive jobs before you make your next career move.</h2>
        </div>
        <div className='col-10 features-div offset-2 ms-4'>
          <div className='icon-container'>
            <img
              style={
                mobile ? { width: 22, height: 22 } : { width: 42, height: 42 }
              }
              className='feature-icon'
              src={require('../../assets/feature-icon4.png')}
              alt=''
            />
          </div>
          <h2>Get skill assessment report and experience certificate.</h2>
        </div>
        <div className='col-10 features-div ms-0'>
          <div className='icon-container'>
            <img
              style={
                mobile ? { width: 22, height: 22 } : { width: 42, height: 42 }
              }
              className='feature-icon'
              src={require('../../assets/feature-icon2.png')}
              alt=''
            />
          </div>
          <h2>Get Automated professional CV and match with top employers.</h2>
        </div>
        <div className='col-10 features-div offset-2 ms-4'>
          <div className='icon-container'>
            <img
              style={
                mobile ? { width: 22, height: 22 } : { width: 42, height: 42 }
              }
              className='feature-icon'
              src={require('../../assets/feature-icon1.png')}
              alt=''
            />
          </div>
          <h2>Unlock your future with smart Job Suggestions.</h2>
        </div>
      </div>
    </div>
  )
}

export default MobileFeature
