import React from 'react';
import ContactUsDesktop from './ContactUsDesktop';
import ContactUsMobile from './ContactUsMobile';
import ContactUsTablet from './ContactUsTablet';
import { useMediaQuery } from 'react-responsive';

const ContactUs = () => {

  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const isTablet = useMediaQuery({ query: '(min-width: 768px) and (max-width: 1024px)' });
  const isDesktop = useMediaQuery({ query: '(min-width: 1025px)' });

  return (
    <div>
      {
        isMobile ? <ContactUsMobile /> : isTablet ? <ContactUsTablet /> : <ContactUsDesktop />
      }
    </div>
  )
}

export default ContactUs;