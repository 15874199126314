import React from 'react';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from './reducers/index'

// const persistConfig = {
//   key: 'authType',
//   storage: storage,
//   whitelist: ['authType']
// };

const persistConfig = {
  key: 'root', // You might want to name this root if it is the root of your state
  storage: storage,
  whitelist: ['authType', 'home'] // Add 'home' to persist the home reducer
};


const pReducer = persistReducer(persistConfig, rootReducer);
const middleware = applyMiddleware(thunk);
const store = createStore(pReducer, middleware);
const persistor = persistStore(store);
export { persistor, store };