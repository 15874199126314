import React, { useState } from 'react'
import './contactusmobile.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faYoutube,
  faInstagram,
  faLinkedin
} from '@fortawesome/free-brands-svg-icons'
import { useDispatch } from 'react-redux'
import { postInformationToDiscord, saveEmailId } from '../../action/HomePage.action'

const ContactUsMobile = () => {
  const dispatch = useDispatch()

  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    message: ''
  })

  const handleChange = e => {
    const { id, value } = e.target
    setFormData({
      ...formData,
      [id]: value
    })
  }

  const handleSubmit = e => {
    e.preventDefault()

    console.log('Form data:', formData)
    dispatch(saveEmailId(formData.email))
    dispatch(postInformationToDiscord(formData))
  }

  return (
    <div
      className='col-12 d-flex justify-content-center align-items-center'
      style={{ minHeight: '70vh' }}
    >
      <div className='row mx-lg-5  d-flex flex-row d-flex justify-content-center cm-main-container'>
        <div className='text-box d-flex justify-content-center align-items-center flex-column'>
          <h1 className='cm-heading'>
            <strong className='text-center'>Contact Us</strong>
          </h1>
          <form
            className='mb-2 mt-2 d-flex justify-content-center align-items-center flex-column'
            onSubmit={handleSubmit}
          >
            <div className='mb-1 col-12'>
              <label className='cm-form-label' htmlFor='fullName'>
                Full Name
              </label>
              <input
                type='text'
                className='cm-form-control'
                id='fullName'
                value={formData.fullName}
                onChange={handleChange}
              />
            </div>

            <div className='mb-1 col-12'>
              <label className='cm-form-label' htmlFor='email'>
                E-mail
              </label>
              <input
                type='email'
                className='cm-form-control'
                id='email'
                value={formData.email}
                onChange={handleChange}
              />
            </div>

            <div className='mb-1 col-12'>
              <label className='cm-form-label ' htmlFor='message'>
                Message
              </label>
              <textarea
                className='cm-form-control cm-message-area'
                id='message'
                value={formData.message}
                onChange={handleChange}
              ></textarea>
            </div>
            <button type='submit' className='cm-btn cm-btn-dark  '>
              Contact Us
            </button>
          </form>
        </div>
        <div className='cm-icon-box d-flex flex-row justify-content-center align-items-center '>
          <a
            href='https://www.youtube.com/@terafactechnologies3317'
            target='_blank'
            rel='noreferrer'
            className='cm-social-media-icon'
          >
            <FontAwesomeIcon color='#25B3BC' icon={faYoutube} />
          </a>
          <a
            href='https://www.linkedin.com/company/terafac-technologies-pvt-ltd/'
            target='_blank'
            rel='noreferrer'
            className='cm-social-media-icon'
          >
            <FontAwesomeIcon color='#25B3BC' icon={faLinkedin} />
          </a>
          <a
            href='https://www.instagram.com/terafactech/'
            target='_blank'
            rel='noreferrer'
            className='cm-social-media-icon'
          >
            <FontAwesomeIcon color='#25B3BC' icon={faInstagram} />
          </a>
        </div>

        <div className='d-flex flex-row justify-content-between align-items-center'>
          <div className='cm-office-box d-flex flex-column justify-content-end align-items-'>
            <h4 className='p-0 m-0'>
              <a className='cm-office-title'>Contact</a>
            </h4>
            <a className='cm-contact-location text-decoration-none'>hello@terafac.live</a>
          </div>

          <div className='cm-office-box d-flex flex-column justify-content-end align-items-'>
            <h4 className='p-0 m-0'>
              <a className='cm-office-title'>Office</a>
            </h4>
            <a className='cm-contact-location text-decoration-none'>India</a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactUsMobile
