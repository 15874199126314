import React from 'react'
import './navbar.css'
import { useState, useEffect } from 'react'
import { FaBars } from 'react-icons/fa'
import EmailPopup from '../EmailPopup/EmailPopup'
import { useDispatch, useSelector } from 'react-redux'
import { postInformationToDiscord } from '../../action/HomePage.action'

const Navbar = () => {
  const [colorChange, setColorchange] = useState(false)
  const [isToggled, setIsToggled] = useState(false)

  const [showEmailPopup, setShowEmailPopup] = useState(false)

  const [popupContent, setPopupContent] = useState({ href: '', buttonText: '' })

  const savedEmail = useSelector(state => state.home.email)

  const dispatch = useDispatch()

  const changeNavbarColor = () => {
    if (window.scrollY >= 580) {
      setColorchange(true)
    } else {
      setColorchange(false)
    }
  }

  const toggleButton = () => {
    setIsToggled(!isToggled) // Toggle the state between true and false
  }

  const scrollToSection = sectionId => {
    console.log('section Id :', sectionId)
    const section = document.getElementById(sectionId)
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const handleEmailPopupClose = () => {
    setShowEmailPopup(false)
  }

  function encodeEmailToBase64 (email) {
    const encodedEmail = btoa(email)
    return encodedEmail
  }

  const handleEmailPopupOpen = event => {
    // alert("handleEmailPopupOpen function called")
    console.log('Navbar handleEmailPopupOpen savedEmail', savedEmail)

    const encodedEmail = encodeEmailToBase64(savedEmail)
    const buttonText = event.target.innerText
    const buttonName = event.target.getAttribute('name')
    const href = event.target.getAttribute('href')

    if (buttonName == 'Enter JEX') {
      if (savedEmail == '') {
        event.preventDefault()
        const baseUrl = event.currentTarget.getAttribute('href')
        const fullUrl = `${baseUrl}`
        window.location.href = fullUrl
      } else {
        dispatch(postInformationToDiscord(savedEmail))
        event.preventDefault()
        const baseUrl = event.currentTarget.getAttribute('href')
        const fullUrl = `${baseUrl}?email=${encodedEmail}`
        window.location.href = fullUrl
      }
    } else if (buttonName == 'Enter Discord') {
      if (savedEmail == '') {
        // alert(href)
        event.preventDefault()
        setPopupContent({ href, buttonText })
        setShowEmailPopup(true)
      } else {
        dispatch(postInformationToDiscord(savedEmail))
        event.preventDefault()
        const baseUrl = event.currentTarget.getAttribute('href')
        const fullUrl = `${baseUrl}`
        window.location.href = fullUrl
      }
    } else {
      if (savedEmail == '') {
        // alert(href)
        event.preventDefault()
        setPopupContent({ href, buttonText })
        setShowEmailPopup(true)
      } else {
        dispatch(postInformationToDiscord(savedEmail))
        event.preventDefault()
        const baseUrl = event.currentTarget.getAttribute('href')
        const fullUrl = `${baseUrl}?email=${encodedEmail}`
        window.location.href = fullUrl
      }
    }
  }

  window.addEventListener('scroll', changeNavbarColor)
  return (
    <nav
      className={
        colorChange
          ? 'navbar navbar-expand-lg navbar-light fixed-top colorChange'
          : 'navbar navbar-expand-lg navbar-light fixed-top'
      }
    >
      <div className={isToggled ? 'nav-bg container-fluid' : 'container-fluid'}>
        <a className='navbar-brand text-white' href='#'>
          <img
            src={require('../../assets/terafac_live_logo.png')}
            alt=''
            width='55'
            height='55'
            className='d-inline-block align-text-center rounded-circle'
          />
          <p className='navbar-title p-0 m-0'>
            Terafac <span style={{ fontWeight: 400 }}>Live</span>
          </p>
        </a>

        <button
          className='navbar-toggler bg-white '
          type='button'
          data-bs-toggle='collapse'
          data-bs-target='#navbarSupportedContent'
          aria-controls='navbarSupportedContent'
          aria-expanded='false'
          aria-label='Toggle navigation'
          onClick={toggleButton}
        >
          {/* <span className='navbar-toggler-icon'></span> */}
          <FaBars />
        </button>
        <div className='collapse navbar-collapse' id='navbarSupportedContent'>
          <ul className='navbar-nav nav-alignment d-flex justify-content-center align-items-md-start align-items-lg-center align-items-xl-center align-items-xxl-center ms-auto mb-2 mb-lg-0'>
            {/*<li className='mx-3 nav-item'>
              <a
                className='p-0 nav-link active text-white fs-5'
                aria-current='page'
                href='#'
              >
                Home
              </a>
            </li>*/}
            <li className='mx-3 nav-item'>
              <a
                onClick={handleEmailPopupOpen}
                className='nav-link text-white fs-5 mx-0 px-0'
                href='https://enterprise.terafac.live/'
              >
                {/*onClick={() => scrollToSection('#about')}>*/}
                Enterprise
              </a>
            </li>
            <li className='mx-3 nav-item'>
              <a
                name='Enter JEX'
                className='p-0 nav-link text-white fs-5 mx-0 px-0'
                // href='https://jex.terafac.live/'
                href='https://jex.terafac.live'
                onClick={handleEmailPopupOpen}
              >
                JEX
              </a>
            </li>
            <li className='mx-3 nav-item'>
              <a
                className='nav-link text-white fs-5 mx-0 px-0'
                href='https://mentor.terafac.live/'
                onClick={handleEmailPopupOpen}
              >
                {/*onClick={() => scrollToSection('#about')}>*/}
                Mentor
              </a>
            </li>
            <li className='mx-3 nav-item'>
              <a
                type='button'
                name='Enter Discord'
                onClick={handleEmailPopupOpen}
                className='btn discord-button fs-5'
                href='https://discord.gg/s22CzNab7E'
              >
                Get Early Access
              </a>
            </li>
          </ul>
        </div>
      </div>

      {showEmailPopup && (
        <EmailPopup
          buttonName={popupContent.buttonText}
          hrefUrl={popupContent.href}
          onClose={handleEmailPopupClose}
        />
      )}
    </nav>
  )
}

export default Navbar
