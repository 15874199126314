import data from '../config/data.json' // Import local user profile data

// fetches local data by default and if it is not present then calls the api
// export const fetchVirtualJobs = () => async (dispatch, getState) => {
//     try {
//         let virtualJobsData = userProfileData;

//         if (!jobFiltersResponse) {
//             // const url = "https://api.repetition.live/api/v1/get-jobs-filters";
//             const response = await fetch(url, {
//                 method: "GET",
//                 headers: {
//                     Accept: "application/json",
//                     "Content-Type": "application/json"
//                 }
//             });

//             jobFiltersResponse = await response.json();
//         }

//         dispatch({
//             type: "FETCH_ViRTUAL_JOBS",
//             payload: jobFiltersResponse
//         });
//     } catch (err) {
//         console.log("HomePage.action.js fetchVirtualJobs", err.message);
//     }
// };

export const postInformationToDiscord =
  formData => async (dispatch, getState) => {
    let userInfo = formData
    console.log('HomePage postInformationToDiscord() userInfo: ', userInfo)
    // alert("Inside the postInformationToDiscord")
    // alert(userInfo)
    try {
      const pseudoname = 'terafac.live enquiry'
      const url =
        'https://discord.com/api/webhooks/1162713198533820467/bgCCcBUBwJxdDGs46LD0MTXH_9rZRrmwy7O821c3YcCFKVSiqIrGl6jjyY1ZLCLZaGdX'
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          username: pseudoname,
          content: JSON.stringify(userInfo),
          tts: false,
          color: 'white'
        })
      })
      const discordResponse = await response.text()
      console.log('postInformationToDiscord', discordResponse)
    } catch (err) {
      console.log('postInformationToDiscord', err.message)
    }
  }

export const saveEmailId = email => async (dispatch, getState) => {
  console.log('HomePage.action.js saveEmailId', email)
  try {
    dispatch({
      type: 'SAVE_EMAIL_ID',
      payload: email
    })
  } catch (err) {
    console.log('HomePage.action.js saveEmailId()', err.message)
  }
}

// export const postInformationToDiscord =
//   (email) =>
//   async (dispatch, getState) => {
//     let userInfo = {
//       email
//     }
//     // console.log("content variable inside postSummaryToDiscord()  : ", userInfo);
//     try {
//       const pseudoname = 'Dashboard'
//       const url =
//         'https://discord.com/api/webhooks/1162713198533820467/bgCCcBUBwJxdDGs46LD0MTXH_9rZRrmwy7O821c3YcCFKVSiqIrGl6jjyY1ZLCLZaGdX'
//       const response = await fetch(url, {
//         method: 'POST',
//         headers: {
//           Accept: 'application/json',
//           'Content-Type': 'application/json'
//         },
//         body: JSON.stringify({
//           username: pseudoname,
//           content: JSON.stringify(userInfo), // Convert userInfo to a JSON string
//           tts: false,
//           color: 'white'
//         })
//       })
//       const discordResponse = await response.text()
//       // console.log("postInformationToDiscord", discordResponse);

//       dispatch({
//         type: 'SAVE_USER_EMAIL',
//         payload: email
//       })
//     } catch (err) {
//       // console.log("postInformationToDiscord", err.message);
//     }
//   }
