import React from 'react'
import './desktopfeature.css'
import { useMediaQuery } from 'react-responsive';

const DesktopFeature = () => {

  const mobile = useMediaQuery({ query: '(max-width: 640px)' })

  return (
    <div className='df-terafac-live df-feature-column d-flex flex-sm-column flex-md-column flex-lg-row flex-xl-row flex-xxl-row'>
      <div className='df-feature-first-column col-sm-12 col-md-12 col-lg-5 col-xl-5 col-xxl-5'>
        <div className='df-first-pic-div'>
          <img
            className='df-feature-first-pic'
            src={require('../../assets/feature-pic3.jpg')}
            alt=''
          />
        </div>
        <div className='df-second-pic-div'>
          <img
            className='df-feature-second-pic'
            src={require('../../assets/feature-pic1.jpg')}
            alt=''
          />
          <h1 className='fs-xl-heading'>
            Terafac <span style={{fontWeight: 400}}>Live</span>
          </h1>
          <img
            className='df-feature-third-pic'
            src={require('../../assets/feature-pic2.jpg')}
            alt=''
          />
        </div>
      </div>
      <div className='df-feature-second-column col-sm-12 col-md-12 col-lg-7 col-xl-7 col-xxl-7'>
        <div className='col-9 df-features-div'>
          <div className='df-icon-container'>
            <img
              style={mobile ? { width: 25, height: 25 } : { width: 40, height: 40 }}
              className='df-feature-icon'
              src={require('../../assets/feature-icon3.png')}
              alt=''
            />
          </div>
          <h2 className='fs-xl-para'>Test drive jobs before you make your next career move.</h2>
        </div>
        <div className='col-9 df-features-div offset-2'>
          <div className='df-icon-container'>
            <img
              style={mobile ? { width: 22, height: 22 } : { width: 32, height: 32 }}
              className='df-feature-icon'
              src={require('../../assets/feature-icon4.png')}
              alt='image'
            />
          </div>
          <h2 className='fs-xl-para'>Get skill assessment report and experience certificate.</h2>
        </div>
        <div className='col-9 df-features-div'>
          <div className='df-icon-container'>
            <img
              style={mobile ? { width: 22, height: 22 } : { width: 32, height: 32 }}
              className='df-feature-icon'
              src={require('../../assets/feature-icon2.png')}
              alt=''
            />
          </div>
          <h2 className='fs-xl-para'>Get Automated professional CV and match with top employers.</h2>
        </div>
        <div className='col-9 df-features-div offset-2'>
          <div className='df-icon-container'>
            <img
              style={mobile ? { width: 22, height: 22 } : { width: 32, height: 32 }}
              className='df-feature-icon'
              src={require('../../assets/feature-icon1.png')}
              alt=''
            />
          </div>
          <h2 className='fs-xl-para'>Unlock your future with smart Job Suggestions.</h2>
        </div>
      </div>
    </div>
  )
}

export default DesktopFeature;
